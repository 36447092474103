import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link, navigate } from 'gatsby'

const NotFoundPage = ({
  data,
  location
}) => {
  const {
    allContentfulLocale,
    contentfulLocale,
    contentfulPageLandingpage,
  } = data

  useEffect(() => {
    const path = location.pathname
    const pathArray = path.split('/')
    let hasLocale = false

    allContentfulLocale.edges.forEach((currentLocale) => {
      hasLocale = pathArray[1] === currentLocale.node.code
    })

    if (contentfulPageLandingpage && hasLocale) {
      navigate(`/${pathArray[1]}/404`, { replace: false })
    } else if (contentfulPageLandingpage && !hasLocale) {
      navigate(`/${contentfulLocale.code}/404`, { replace: false })
    }
  }, [
    allContentfulLocale,
    contentfulPageLandingpage,
    contentfulLocale.code,
    location
  ])

  if (contentfulPageLandingpage) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>BEGA Connect | Page not found</title>
      </Helmet>
      <div className="section">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell">

              <h1>
                <span>Error 404</span>
                <br/>
                Page not found 📺
              </h1>

              <p>
                Sorry <span role="img" aria-label="Pensive emoji">😔</span> we couldn’t find what you were looking for.
                <br />
                { process.env.NODE_ENV === "development" ? (
                  <>
                    <br />
                    Try creating a page in <code>src/pages/</code>.
                    <br />
                  </>
                ) : null }
                <br />
                <Link to="/" className="navigation__item button button__primary">
                  <span className="a-icon"></span>
                  Startseite
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage

export const notFoundData = graphql`
  query NotFoundQuery {
    contentfulLocale (default: {eq: true}){
      code
      name
    }
    allContentfulLocale {
      totalCount
      edges {
        node {
          code
        }
      }
    }
    contentfulPageLandingpage(slug: { eq: "404" }) {
      ...pageLandingpageFields
    }
  }
`
